<template>
  <div>
    <div class="flex items-center text-center text-gray-900">
      <button
        type="button"
        class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        @click="previousMonth"
      >
        <span class="sr-only">Previous month</span>
        <svg
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div class="flex-auto text-lg leading-6 font-semibold">
        <span v-if="person">{{ person.name }} / </span>
        {{ month }}
        <span
          v-if="isLoading"
          class="absolute dib ml-3"
        >
          <svg
            class="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="white"
              stroke-width="4"
            />
            <path
              class="opacity-75"
              fill="#999"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </span>
      </div>
      <button
        type="button"
        class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        @click="nextMonth"
      >
        <span class="sr-only">Next month</span>
        <svg
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div class="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
      <div class="text-center">
        S<span class="hidden md:inline-block">unday</span>
      </div>
      <div class="text-center">
        M<span class="hidden md:inline-block">onday</span>
      </div>
      <div class="text-center">
        T<span class="hidden md:inline-block">uesday</span>
      </div>
      <div class="text-center">
        W<span class="hidden md:inline-block">ednesday</span>
      </div>
      <div class="text-center">
        T<span class="hidden md:inline-block">hursday</span>
      </div>
      <div class="text-center">
        F<span class="hidden md:inline-block">riday</span>
      </div>
      <div class="text-center">
        S<span class="hidden md:inline-block">aturday</span>
      </div>
    </div>
    <div class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg shadow ring-1 ring-slate-200 bg-slate-200 text-sm">
      <button
        v-for="(date, index, total) in dates"
        :key="`calendar-${date.toString()}`"
        type="button"
        :class="dateButtonClass(date, index, dates.length)"
        :aria-label="`Select date ${dateStamp(date)}`"
        @click="changeDate(date)"
      >
        <span class="mx-auto flex h-7 w-7 items-center justify-center rounded-full">
          {{ dateNumber(date) }}
        </span>
        <ul
          v-if="eventsForDate(date).length"
          class="list"
        >
          <li
            v-for="event in eventsForDate(date)"
            :key="`calendar-event.id`"
            class="text-xs"
          >
            {{ event.name }}
          </li>
        </ul>
      </button>
    </div>
    <p
      v-if="currentDateInfo.message"
      :class="currentDateMessageClass"
      v-html="currentDateInfo.message"
    />
    <slot
      :date="dateStamp(date)"
      :availableCategories="currentDateInfo.available_categories"
      :initialCategoryId="initialCategoryId"
      :categoryHasPhysicianNote="categoryHasPhysicianNote"
    />
  </div>
</template>

<script>
import { nextTick } from 'vue'

const pad = (n, width, z) => {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

const dateStamp = (date) => {
  return [date.getFullYear(), pad(date.getMonth() + 1, 2, 0), pad(date.getDate(), 2, 0)].join('-')
}

const dateNumber = (date) => {
  return date.toLocaleDateString('en-us', { day: 'numeric' })
}

const dateMonth = (date) => {
  return date.toLocaleDateString('en-us', { year: 'numeric', month: 'long' })
}

const isDate = (date, today) => {
  return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
}

const isInPast = (date, todayMarker) => {
  const today = new Date(todayMarker.getFullYear(), todayMarker.getMonth(), todayMarker.getDate())

  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)

  return today.getTime() > date.getTime()
}

const isPastEnd = (date, endAt) => {
  return endAt && isInPast(endAt, date)
}

const isInMonth = (date, dateWithMonth) => {
  return date.getMonth() === dateWithMonth.getMonth() &&
        date.getFullYear() === dateWithMonth.getFullYear()
}

export default {
  props: {
    initialDate: {
      type: String,
      default () { return dateStamp(new Date()) }
    },
    endAt: {
      type: String,
      default: ''
    },
    initialCategoryId: {
      type: String,
      default: ''
    },
    isLarge: {
      type: Boolean,
      default: false
    },
    datesData: {
      type: Object,
      default () { return {} }
    },
    isSelectable: {
      type: Boolean,
      default: true
    },
    calendarUrl: {
      type: String,
      default: ''
    },
    person: {
      type: Object,
      default: ''
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const splat = this.initialDate.split('-')
    const date = new Date(splat[0], parseInt(splat[1]) - 1, splat[2])

    let calendarEndAt = null

    if (this.endAt) {
      const endSplat = this.endAt.split('-')
      calendarEndAt = new Date(endSplat[0], parseInt(endSplat[1]) - 1, endSplat[2])
    }

    return {
      date,
      calendarEndAt,
      isLoading: false,
      calendarDate: date,
      categoryHasPhysicianNote: false
    }
  },
  computed: {
    month () {
      return dateMonth(this.calendarDate)
    },
    dates () {
      const dates = []
      const startDate = new Date(this.calendarDate.getTime())
      const endDate = new Date(this.calendarDate.getTime())

      startDate.setDate(1)
      startDate.setDate(startDate.getDate() + (startDate.getDay() * -1))

      endDate.setDate(1)
      endDate.setMonth(endDate.getMonth() + 1)
      endDate.setDate(endDate.getDate() - 1)
      endDate.setDate(endDate.getDate() + (7 - endDate.getDay()))

      let date = startDate

      while (date < endDate) {
        dates.push(date)
        date = new Date(date.getTime())
        date.setDate(date.getDate() + 1)
      }

      return dates
    },
    currentDateInfo () {
      return this.datesData[dateStamp(this.date)] || {}
    },
    currentDateMessageClass () {
      return `bg-${this.currentDateInfo.bg}-100 text-${this.currentDateInfo.bg}-800 p-5 mt-5 rounded-md`
    }
  },
  mounted () {
    nextTick(() => {
      this.calendarDate = this.date

      const $categorySelect = this.$el.querySelector('[data-category-selection]')

      if ($categorySelect) {
        $categorySelect.addEventListener('input', () => this.triggerCategoryChange($categorySelect))
        this.triggerCategoryChange($categorySelect)
      }
    })
  },
  methods: {
    triggerCategoryChange ($categorySelect) {
      const categoryId = $categorySelect.value
      this.categoryHasPhysicianNote = !!this.currentDateInfo.available_categories.find((c) => c.id === categoryId && c.has_physician_note)
    },
    eventsForDate (date) {
      const data = this.datesData[dateStamp(date)]

      return data && data.events ? data.events : []
    },
    nextMonth () {
      const newDate = new Date(this.calendarDate.getTime())

      newDate.setMonth(newDate.getMonth() + 1)

      this.loadData(newDate)
    },
    previousMonth () {
      const newDate = new Date(this.calendarDate.getTime())

      newDate.setMonth(newDate.getMonth() - 1)

      this.loadData(newDate)
    },
    loadData (date) {
      this.isLoading = true

      window.fetch(`${this.calendarUrl}?date=${dateStamp(date)}${this.person ? `&person_id=${this.person.id}` : ''}`)
        .then((response) => response.json())
        .then((data) => {
          for (const date in data) {
            this.datesData[date] = data[date]
          }

          this.calendarDate = date
        })
        .catch((err) => alert(err.message))
        .finally(() => {
          this.isLoading = false
        })
    },
    dateButtonClass (date, index, total) {
      const classes = this.isLarge ? ['py-3.5 md:py-10'] : ['py-1.5 md:py-4']
      const numberOfTeamMembersOffForDate = 0
      const teamMembersOffWarningColor = 'sky'
      const data = this.datesData[dateStamp(date)] || {}

      if (index === 0) classes.push('rounded-tl-lg')
      if (index === 6) classes.push('rounded-tr-lg')
      if (index === total - 1) classes.push('rounded-br-lg')
      if (index + 7 === total) classes.push('rounded-bl-lg')

      // We're not giving all the events to the client, so we need to check isInPast this here
      if (!this.isAdmin && (isInPast(date, new Date()) || isPastEnd(date, this.calendarEndAt) || data.bg === 'slate')) {
        classes.push('bg-slate-400', 'text-slate-500')

        if (this.isSelectable) {
          classes.push('cursor-not-allowed')
        }
      } else {
        if (this.isSelectable) {
          classes.push('cursor-pointer')
        }

        if (data.bg) {
          classes.push(`bg-${data.bg}-200`)
        // } else if (!isInMonth(date, this.calendarDate)) {
        //   classes.push('bg-slate-100')
        } else {
          classes.push('bg-white')
        }
      }

      if (isDate(date, new Date())) {
        classes.push('font-bold')
      }

      if (isDate(date, this.date)) {
        classes.push('outline', 'outline-4', 'outline-sky-600', 'text-sky-600', 'font-bold', 'text-lg', 'z-50')
      }

      return classes.join(' ')
    },
    changeDate (date) {
      if (!this.isSelectable) {
        return
      }

      if (!this.isAdmin && (isInPast(date, new Date()) || isPastEnd(date, this.calendarEndAt))) {
        return
      }

      const data = this.datesData[dateStamp(date)] || {}

      if (!this.isAdmin && data.bg === 'slate') {
        return
      }

      this.date = new Date(Date.parse(date))
    },

    dateStamp,
    dateNumber
  }
}
</script>
