import { createApp } from 'vue'
import Calendar from '~/components/calendar'

const mountCalendar = (el) => {
  if (!document.querySelector(el)) {
    return
  }

  const app = createApp({
    components: {
      Calendar
    }
  })

  app.mount(el)
}

const mountMobileMenuToggle = () => {
  const $toggle = document.getElementById('mobile-menu-toggle')
  const $menu = document.getElementById('mobile-menu')

  $toggle.addEventListener('click', () => {
    if ($menu.className.indexOf('block') !== -1) {
      $menu.className = $menu.className.replace('block', 'hidden')
    } else {
      $menu.className = $menu.className.replace('hidden', 'block')
    }
  })
}

const dataConfirm = () => {
  document.querySelectorAll('[data-confirm]').forEach((el) => {
    el.addEventListener('click', (event) => {
      if (!confirm(el.getAttribute('data-confirm'))) {
        event.preventDefault()
        event.stopImmediatePropagation()

        return false
      }
    })
  })
}

mountCalendar('#vue-wrapper')
mountMobileMenuToggle()
dataConfirm()
